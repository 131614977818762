import MyEventEmitter from 'service/MyEventEmitter';

class ChatServerEndpoint extends MyEventEmitter {
	constructor() {
		super();
		this.keepAliveInterval = null;
		this._events.connected = [];
		this._events.messageReceived = [];
		this.openHandler = this.handleOpen.bind(this);
		this.responseHandler = this.handleResponse.bind(this);
		this.chatRoom = null;
		this.socket = null;
		this.initialized = false;
		this.error = null;
	}

	openConnection() {
		console.log('initialize chat connection');
		this.socket = new WebSocket(
			`ws${process.env.REACT_APP_CHAT_SECURE === 'true' ? `s` : ``}://${
				process.env.REACT_APP_CHAT_HOST
			}${
				process.env.REACT_APP_CHAT_PORT.length > 0
					? `:${process.env.REACT_APP_CHAT_PORT}`
					: ``
			}${process.env.REACT_APP_CHAT_PATH}`
		);
		this.socket.addEventListener('message', this.responseHandler);
		this.socket.addEventListener('open', this.openHandler);

		this.socket.onerror = (event) => {
			if (this.socket.readyState !== 1) {
				console.log('Sorry, chat connection failed!');
				this.error = event;
			}
		};
	}

	closeConnection() {
		console.log('close connection');
		this.initialized = false;
		if (this.keepAliveInterval !== null)
			clearInterval(this.keepAliveInterval);
		this.socket.removeEventListener('message', this.responseHandler);

		this.socket.close();
	}

	sendMessage(message) {
		this.socket.send(JSON.stringify(message));
	}

	handleOpen() {
		this.emit('connected');
		this.keepAliveInterval = setInterval(this.keepAlive.bind(this), 10000);
	}

	handleResponse(e) {
		const data = JSON.parse(e.data);
		if (data.messageType && data.messageType !== 'PONG') {
			this.emit('messageReceived', data);
		}
	}

	keepAlive() {
		this.socket.send(JSON.stringify({ messageType: 'PING' }));
	}
}

export default ChatServerEndpoint;
