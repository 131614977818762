import axios from 'axios';
// import _ from '@lodash';
// import { useSelector } from 'react-redux';
import { useState, createContext, useContext, useRef, useEffect } from 'react';
import ChatServerEndpoint from 'service/ChatServerEndpoint';
import MessageType from 'dto/MessageType';

export const ChatContext = createContext(null);

export const useChat = () => {
	return useContext(ChatContext);
};

export default function ChatProvider(props) {
	const endpointRef = useRef(null);
	// const authUser = useSelector(({ auth }) => auth.user);
	const [user, setUser] = useState(null);
	const [chatRoom, setChatRoom] = useState(null);
	const [messageList, setMessageList] = useState([]);
	const messageListRef = useRef([]);
	const [connected, setConnected] = useState(false);

	messageListRef.current = messageList;

	// useEffect(() => {
	// 	if (user && user.id) {
	// 		if (!connected) endpointRef.current.openConnection();
	// 	} else if (connected) {
	// 		endpointRef.current.closeConnection();
	// 	}
	// }, [user]);

	useEffect(() => {
		if (endpointRef.current === null) {
			endpointRef.current = new ChatServerEndpoint();
			endpointRef.current.on('messageReceived', handleMessageReceived);
			endpointRef.current.on('connected', handleConnected);
		}
		if (!connected) endpointRef.current.openConnection();

		// const url = 'http://localhost:3000/edubot-portal/methods/visitorControl/login'
		/*const url = '/edubot-portal/methods/visitorControl/login'
		const body = { 'name': 'robert_benko-szaki', 'email': 'robert.benko-szaki@creo.hu' }

		axios.post(url, body).then(function (response) {
			setUser({
				id: 1,
				name: 'robert_benko-szaki',
				nickName: 'robert_benko-szaki'
			});
			if (!connected) endpointRef.current.openConnection();
		}).catch(function (error) {
			console.log(error);
		});*/

		return () => {
			if (endpointRef.current !== null) {
				endpointRef.current.removeListener(
					'messageReceived',
					handleMessageReceived
				);
				endpointRef.current.removeListener(
					'connected',
					handleConnected
				);
				endpointRef.current.closeConnection();
				endpointRef.current = null;
			}
		};
	}, []);

	useEffect(() => {
		if (connected) sendWhoamiMessage('VISITOR');
	}, [connected]);

	const close = () => {
		endpointRef.current.closeChat();
	};

	const handleConnected = () => {
		setConnected(true);
	};
	const handleMessageReceived = (data) => {
		if (data.messageType === 'VISITORINFO') {
			const dto = data.message;
			setUser({
				id: dto.id,
				name: dto.name,
				nickName: dto.name,
			});
		} else setMessageList([...messageListRef.current, data]);
	};

	const sendWhoamiMessage = (userType) => {
		const message = {
			sender: null,
			messageType: MessageType.WHOAMI,
			timestamp: new Date(),
			message: { type: userType },
		};
		endpointRef.current.sendMessage(message);
	};

	const sendTextMessage = (messageText) => {
		const message = {
			sender: user.nickName,
			messageType: MessageType.TEXT,
			timestamp: new Date(),
			message: messageText,
		};
		endpointRef.current.sendMessage(message);
	};

	const sendChosenOptionMessage = (option) => {
		const message = {
			sender: user.nickName,
			messageType: MessageType.CHOSENOPTION,
			timestamp: new Date(),
			message: option,
		};
		endpointRef.current.sendMessage(message);
	};

	const changeChatRoom = (newChatRoom) => {
		if (chatRoom !== null) {
			leave();
		}
		if (newChatRoom !== null) {
			join(newChatRoom);
		}
		setChatRoom(newChatRoom);
	};

	const join = (newChatRoom) => {
		const message = {
			sender: user.nickName,
			messageType: MessageType.JOINED,
			timestamp: new Date(),
			message: newChatRoom,
		};
		endpointRef.current.sendMessage(message);
	};

	const leave = () => {
		const message = {
			sender: user.nickName,
			messageType: MessageType.LEFT,
			timestamp: new Date(),
			message: chatRoom,
		};
		endpointRef.current.sendMessage(message);
	};

	const value = {
		connected,
		chatRoom,
		changeChatRoom,
		user,
		messageList,
		sendTextMessage,
		sendChosenOptionMessage,
		close,
	};

	return (
		<ChatContext.Provider value={value}>
			{props.children}
		</ChatContext.Provider>
	);
}
