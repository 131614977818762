import Chat from 'components/Chat';
import ChatProvider from 'provider/ChatProvider';

export default function App() {
  return (
    <div className='relative w-full min-h-screen bg-white flex items-center justify-center p-16' >

      <div className='absolute w-full h-full top-0 left-0 bg-cover grayscale-[0.9] opacity-50 blur-sm' style={{ backgroundImage: 'url(/assets/images/background.jpg)' }}/>

      <ChatProvider>
        <Chat className='z-10'/>
      </ChatProvider>

    </div>
  );
}